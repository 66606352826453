<template>
<PastEventList></PastEventList>
</template>

<script>
import PastEventList from "@/components/blocks/PastEventList";
export default {
  name: "Events",
  components: {PastEventList}
}
</script>

<style scoped>

</style>