<template>
  <div class="card lg:w-3/12 md:w-6/12 sm:w-full">
    <div class="h-full bg-gray-200 rounded-md">
      <div class="card-top px-4 py-4 mb-2 flex justify-between items-center">
        <div class="card-title flex items-center">
            <span class="anticon bg-blue-100 w-10 h-10 rounded-3xl">
                <font-awesome-icon icon="camera-retro" class="text-blue-500 text-base h-10"/>
            </span>
          <span class="text-lg font-medium ml-3">Kayıtlı etkinlikler</span>
        </div>

      </div>
      <div class="card-center card-tabs">
        <a-tabs v-model:activeKey="activeEventTab">
          <a-tab-pane key="3" :tab="$t('events.event-block.past-events')+'('+$store.state.pastEventsPaging.total+')'"
                      force-render>
            <div class="px-4 py-2 pb-6 tab-content">
              <div class="w-full flex flex-row justify-between tab-content__titles mb-3 px-5">
                <div class="md:w-3/12 sm:w-5/12 text-xs">{{ $t('events.event-block.event-name') }}</div>
                <div class="md:w-3/12 sm:w-5/12 text-xs">{{ $t('events.event-block.event-date') }}</div>
                <div class="w-3/12 sm:hidden md:block text-xs">{{ $t('events.event-block.event-platform') }}</div>
                <div class="w-3/12 sm:hidden md:block text-xs">{{ $t('events.event-block.event-influencer') }}</div>
                <div class="md:w-1/12 sm:w-2/12"></div>
              </div>
              <div class="overflow-y-auto" style="height: 57vh;">

                <div class="w-full flex flex-col flex-wrap tab-content__data">
                  <div
                      class="w-full flex flex-row flex-wrap justify-between items-center py-2 px-5 relative border border-gray-301 rounded-md bg-white mb-4 tab-content__data--item"
                      v-for="event in $store.state.pastEvents" :key="event.id" :value="event.id"
                  >
                    <div class="w-full flex flex-row justify-between items-center">
                      <div class="md:w-1/12 sm:w-1/12">
                        <label class="text-xs text-gray-700 flex items-center cursor-pointer">
                          <a-checkbox v-bind:checked="selectedEvent !== null && selectedEvent.id === event.id"
                                      v-bind:value="event" @change="selectedEvent = event"></a-checkbox>
                        </label>
                      </div>
                      <div
                          class="md:w-3/12 sm:w-5/12 text-xs text-darkwhite-950 px-1 text-ellipsis overflow-hidden whitespace-nowrap">
                        {{ event.title }}
                      </div>
                      <div class="md:w-3/12 sm:w-5/12 text-xs text-darkwhite-950 px-1">{{ event.date }}</div>
                      <div class="w-3/12 sm:hidden md:block text-xs text-darkwhite-950 px-1 break-words pr-3">
                        {{ event.platform.domain_name }}
                      </div>
                      <div class="w-3/12 sm:hidden md:block text-xs text-darkwhite-950 px-1">
                        {{ event.streamer != null ? event.streamer.name : "" }}
                      </div>
                      <div class="md:w-1/12 sm:w-2/12 flex items-center justify-between">
                        <!--                        <font-awesome-icon icon="redo-alt" class="text-darkwhite-950 ml-2"></font-awesome-icon>-->
                      </div>
                    </div>
                    <router-link :to="'/user/recorded-streams?type=s3&eventId='+event.id"  class=" w-full sm:hidden md:block text-xs text-darkwhite-950 py-2 text-center font-bold underline">
                      Yayın kayıtları
                    </router-link>
                    <div class="w-full flex items-center mt-3">
                      <div class="md:w-4/12 sm:w-6/12">
                        <div class="text-xs text-darkwhite-950 mb-2">{{ $t('events.event-block.event-likes-count') }}
                        </div>
                        <div class="bg-blue-100 text-text-1 text-xs w-auto rounded-md h-6 leading-6 px-2 inline-block">
                          <font-awesome-icon icon="heart"/>
                          {{ event.statistics.totalLike ? event.statistics.totalLike : 0 }}
                        </div>
                      </div>
                      <div class="md:w-4/12 sm:w-6/12">
                        <div class="text-xs text-darkwhite-950 mb-2">
                          {{ $t('events.event-block.product-click-count') }}
                        </div>
                        <div class="bg-green-200 text-text-2 text-xs w-auto rounded-md h-6 leading-6 px-2 inline-block">
                          <font-awesome-icon icon="location-arrow"/>
                          {{ event.statistics.totalClick ? event.statistics.totalClick : 0 }}
                        </div>
                      </div>
                    </div>
                    <a-dropdown :trigger="['click']" class="tab-data-menu">
                      <a class="text-darkwhite-950 pl-2 absolute top-0 right-0" @click.prevent>
                        <MoreOutlined class="text-lg"/>
                      </a>
                      <template #overlay>
                        <a-menu>
                          <a-menu-item key="0">
                            <router-link to="#">{{ $t('events.event-block.edit-event') }}</router-link>
                          </a-menu-item>
                          <a-menu-item key="1">
                            <router-link to="#">{{ $t('events.event-block.delete-event') }}</router-link>
                          </a-menu-item>
                        </a-menu>
                      </template>
                    </a-dropdown>
                  </div>
                </div>
              </div>
            </div>
            <a-pagination v-if="$store.state.pastEventsPaging.total > 9"
                          v-model:current="$store.state.pastEventsPaging.page"
                          :total="$store.state.pastEventsPaging.total" :pageSize="$store.state.pastEventsPaging.limit"
                          @change="getPastEvents"/>
          </a-tab-pane>
        </a-tabs>
      </div>
    </div>
  </div>
  <template v-if="this.$store.state.layout ==='user'">
    <create-event-form />
    <edit-event-form />
  </template>
  <a-modal
      :title="iframe"
      v-model:visible="frameVisible"
      :footer="null"
  >
    <pre> &lt;style type="text/css"&gt;</pre>
    <pre>   #onlivesale_live_screen {</pre>
    <pre>     display: block;</pre>
    <pre>     width: 100%;</pre>
    <pre>     border: none;</pre>
    <pre>     overflow-y: auto;</pre>
    <pre>     overflow-x: hidden;</pre>
    <pre>     min-height: 450px;</pre>
    <pre>     height: 100%;</pre>
    <pre>   }</pre>
    <pre> &lt;/style&gt;</pre>
    &lt;iframe id="onlivesale_live_screen" src="{{this.url}}/{{this.idForIframe}}?client=iframe"&gt;&lt;/iframe&gt;
  </a-modal>
</template>

<script>
import {MoreOutlined} from '@ant-design/icons-vue';



export default {

  components: {
    MoreOutlined
  },
  data() {
    return {
      activeEventTab: this.$store.state.activeEventTab,
      checked: false,
      liveEvents: [],
      liveEventsPaging: {total: 0, limit: 10, page: 1},
      frameVisible: false,
      url:process.env.VUE_APP_URL,
      idForIframe:0
    }
  },
  computed: {
    selectedEvent: {
      get() {
        return this.$store.state.selectedEvent
      },
      set(val) {
        this.$store.commit('setSelectedEvent', val)
      }
    }
  },
  methods: {
    getPastEvents() {
      this.$swal.showLoading()
      this.$store.dispatch('setPastEvents')
      this.$swal.close()
    },
    selected(id) {
      return id === this.$store.state.selectedEvent.id
    }
  },
  created() {
    console.log("env:"+process.env.VUE_APP_TEST)
    this.$store.commit('setSelectedEvent', {})
    this.$swal.showLoading()
    this.getPastEvents()
    this.$swal.close()
  }
};
</script>
<style>
.play-circle {
  cursor: pointer;
}
</style>